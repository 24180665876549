import ContainerMain from "src/pages/common/ContainerMain"
import image3 from "images/Home/OurProducts/home-products-3.png"
import image4 from "images/Home/OurProducts/home-products-4.png"

import OctoPosVi from "images/Home/OurProducts/OurProducts_OctoPos_Vi.jpg"
import OctoPos from "images/Home/OurProducts/OurProducts_OctoPos.png"
import OctoSellVi from "images/Home/OurProducts/OurProducts_OctoSell_Vi.png"
import OctoSell from "images/Home/OurProducts/OurProducts_OctoSell.png"

import "./OurProducts.scss"
import ImageZoomEffect from "pages/common/ImageZoomEffect"
import { useFormatter } from "../../../helpers/i18n"

export default function OurProducts() {
  const { __, Link, currentLocale } = useFormatter()
  const OctoPosImage = currentLocale === "vi" ? OctoPosVi : OctoPos
  const OctoSellImage = currentLocale === "vi" ? OctoSellVi : OctoSell
  return (
    <>
      <div className="dXmSTTLHQi">
        <ContainerMain>
          <div className="mCjLcmpFPk">
            <div className="HyDfhggjXe">
              <div className="col-empty"></div>
              <div className="col-data">
                <div className="QfDoodNPUg">
                  <div className="section-title rJbDXuhMZw">
                    {__({ defaultMessage: "Our Products" })}
                  </div>

                  <div className="dmOJXeLHPg dmOJXeLHPg-2">
                    <div className="kOKKLQkCvz">
                      <div className="hyCIrFTAOq">
                        <ImageZoomEffect
                          alt=""
                          className="hyCIrFTAOq-img inner-img"
                          loading="lazy"
                          src={image3}
                        />
                      </div>
                    </div>
                    <div className="qOriJZDUwq-home">
                      {__({ defaultMessage: "Performance Dashboard" })}
                    </div>
                    <Link
                      className="btn-learn-more background-red"
                      to="/product-performance"
                    >
                      {__({ defaultMessage: "Learn more" })}
                    </Link>
                  </div>
                </div>
                <div className="LOKkhgrzEe">
                  <div className="dmOJXeLHPg dmOJXeLHPg-1">
                    <div className="kOKKLQkCvz">
                      <div className="hyCIrFTAOq">
                        <ImageZoomEffect
                          alt=""
                          className="hyCIrFTAOq-img inner-img"
                          loading="lazy"
                          src={OctoPosImage}
                        />
                      </div>
                    </div>
                    <div className="qOriJZDUwq-home">
                      {__({
                        defaultMessage:
                          "OctoPOS - Online-Offline Integrated Order Management",
                      })}
                    </div>
                    <Link
                      className="btn-learn-more background-red"
                      to="/product-integrated"
                    >
                      {__({ defaultMessage: "Learn more" })}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
